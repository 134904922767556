'use client'

import { useEffect, useState } from 'react'
import GlobalSearchBar from 'components/GlobalSearchBar'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

const SearchBarFloating = () => {
  const [showHeader, setShowHeader] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0)

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    if (currentScrollY > 100) {
      setShowHeader(true)
    } else {
      setShowHeader(false)
    }
    setLastScrollY(currentScrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY])

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        bgcolor: 'white',
        color: 'white',
        textAlign: 'center',
        transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
        transform: showHeader ? 'translateY(0)' : 'translateY(-100%)',
        opacity: showHeader ? 1 : 0,
        zIndex: '1000',
        padding: '16px',
        maxWidth: '430px',
      }}
    >
      <GlobalSearchBar />
    </Box>
  )
}

export default SearchBarFloating
