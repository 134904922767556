import type { BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { useQuery } from '@tanstack/react-query'

import { getDynamicChannel } from 'app/(home)/_utils/services/dynamicChannel'
import useUserStore from 'store/user'

import DynamicChannel from './DynamicChannel'
import DynamicChannelSkeleton from './components/DynamicChannelSkeleton'

const DynamicChannelContainer = ({ boxProps }: { boxProps?: BoxPropsType }) => {
  const { customerAddress } = useUserStore((state) => state)

  const { data: dynamicChannelResponse, isLoading } = useQuery({
    queryKey: ['dynamic channel'],
    queryFn: async () => {
      const resp = await getDynamicChannel({
        locationid: customerAddress.location_id,
        factive: true,
        fmproduct: true,
        showhub: false,
      })

      return resp.data
    },
  })

  const dynamicChannelList = dynamicChannelResponse?.data || []

  if (isLoading) {
    return <DynamicChannelSkeleton />
  }

  return <DynamicChannel boxProps={boxProps} initialData={dynamicChannelList} />
}

export default DynamicChannelContainer
