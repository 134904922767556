'use client'

import { useEffect, useState, type ReactNode } from 'react'
import { useTranslations } from 'next-intl'

import useLocation from 'hooks/useLocation'

import ConfirmationDialog from 'components/Confirmation'
// import {
//   TrackEventsPropertiesType,
//   getScreenNameMetaFromDocument,
//   trackEvents,
// } from 'lib/mixpanelTracker'
// import { mixpanelEventName } from 'config/mixpanelConstants'

const Location = ({ children }: { children: ReactNode }) => {
  const { getLocation, isPermissionDenied, setPermissionAsked, askedForPermission } = useLocation()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const t = useTranslations('global')

  // const handleTrackEvent = (event: string, options: TrackEventsPropertiesType) => {
  //   const screenName = getScreenNameMetaFromDocument()

  //   trackEvents(event, {
  //     screenName,
  //     eventCategory: screenName,
  //     eventLabel: '',
  //     ...options,
  //   })
  // }

  useEffect(() => {
    if (isPermissionDenied && !askedForPermission) {
      setShowConfirmation(true)
      // handleTrackEvent(mixpanelEventName.VIEW_COMPONENT, {
      //   eventAction: 'user view pop up location permission',
      // })
    }

    return () => {
      setShowConfirmation(false)
    }
  }, [isPermissionDenied, askedForPermission])

  useEffect(() => {
    getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ConfirmationDialog
        open={showConfirmation}
        title={t('permissionDeniedTitle')}
        description={t('permissionDeniedDescription')}
        // handleClose={() => {
        //   setShowConfirmation(false)
        //   setPermissionAsked(true)
        //   handleTrackEvent(mixpanelEventName.CLICK_BUTTON, {
        //     eventAction: `click button on location permission pop up - permission false`,
        //   })
        // }}
        handleOk={() => {
          getLocation()
          setPermissionAsked(true)
          // handleTrackEvent(mixpanelEventName.CLICK_BUTTON, {
          //   eventAction: `click button on location permission pop up - permission true`,
          // })
        }}
        okText="OK"
        withCancelBtn={false}
      />
      {children}
    </>
  )
}

export default Location
