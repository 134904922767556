'use client'

import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { BoxPropsType } from '@astronautsid/wpe-astro-ui'
import { BigCatalogueResponseType } from 'utils/apiList/lite/catalogue'
import Image from 'components/Image'

import { trackAppMonitoring } from 'utils/tracking/mixpanel/mixpanelEventActions'
import { ASSET_PREFIX } from 'config/constants'
import Link from 'next/link'
import { getScreenNameMetaFromDocument, trackEvents } from 'lib/mixpanel/mixpanelTracker'
import { mixpanelEventCategory, mixpanelEventName } from 'utils/tracking/mixpanel/mixpanelConstants'
import { CatalogueV2ContentType } from 'utils/apiList/lite/liteApiTypes'

export type CatalogueV2PropsType = {
  title?: string
  boxProps?: BoxPropsType
  initialData?: CatalogueV2ContentType[]
  initialBigCatalogue?: BigCatalogueResponseType['categories']
}

const CatalogueV2 = ({
  boxProps,
  title,
  initialData = [],
  initialBigCatalogue = [],
}: CatalogueV2PropsType) => {
  const EVENT_ACTION = 'app monitoring - catalogue load'
  const handleTrackCategoryLoadSuccess = () => {
    trackAppMonitoring({
      eventAction: EVENT_ACTION,
      eventLabel: 'success',
    })
  }

  const titleCatalogue = title

  const bigCatalogFirstData = initialBigCatalogue.length ? initialBigCatalogue[0] : null

  const handleTrackEventClick = (
    content: (typeof initialData | typeof initialBigCatalogue)[0],
    index: number,
  ) => {
    const screenName = getScreenNameMetaFromDocument()

    trackEvents(mixpanelEventName.SELECT_PROMOTION, {
      screenName,
      eventAction: `click catalogue - ${String(content.category_name)}`,
      eventCategory: mixpanelEventCategory.HOME_PAGE,
      eventLabel: '',
      items: [
        {
          item_id: content.category_id,
          item_name: `/homepage - banner catalogue`,
          creative_name: content.category_name,
          creative_slot: index,
        },
      ],
    })
  }

  useEffect(() => {
    if (initialData.length) {
      const screenName = getScreenNameMetaFromDocument()
      trackEvents(mixpanelEventName.VIEW_COMPONENT, {
        eventCategory: screenName,
        eventAction: 'view internal asset - catalogue',
        eventLabel: '',
      })
    }
  }, [initialData.length])

  useEffect(() => {
    if (!initialData.length) {
      return
    }

    handleTrackCategoryLoadSuccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box {...boxProps}>
      <Typography variant="headline-default" marginLeft="16px">
        {titleCatalogue}
      </Typography>
      <Box
        display="flex"
        gap="8px"
        overflow="auto"
        whiteSpace="nowrap"
        marginTop="8px"
        className="box-hide-scroll"
        px="16px"
      >
        {/* will render only small tiles if no bigdata present */}
        {/* Large tile */}
        {bigCatalogFirstData && (
          <Link
            href={`/cat/${bigCatalogFirstData?.category_slug}`}
            onClick={() => handleTrackEventClick(bigCatalogFirstData, 0)}
          >
            <Box flexShrink={0} width={112} height={188} position="relative" borderRadius="8px">
              <Image
                src={
                  bigCatalogFirstData?.category_image?.image_url_small ||
                  `${ASSET_PREFIX}/img/placeholder-product.svg`
                }
                alt={bigCatalogFirstData?.category_name}
                width={112}
                height={188}
                style={{ borderRadius: '8px' }}
                fallbackImageSrc={`${ASSET_PREFIX}/img/placeholder-product.svg`}
              />
            </Box>
          </Link>
        )}

        {/* Small tiles */}
        <Box
          display="grid"
          gridAutoFlow="column"
          gridTemplateRows="repeat(2,90px)"
          gap="0.5rem"
          flexShrink={0}
        >
          {initialData.map((item) => (
            <Link
              key={`home-catalogue-${item.category_id}`}
              href={`/cat/${item.category_slug}`}
              onClick={() => handleTrackEventClick(item, 0)}
            >
              <Box
                borderRadius="8px"
                position="relative"
                textAlign="center"
                width="100%"
                height="100%"
              >
                <Image
                  src={
                    item.category_image?.url_small || `${ASSET_PREFIX}/img/placeholder-product.svg`
                  }
                  alt={item.category_name}
                  width={90}
                  height={90}
                  style={{ borderRadius: '8px', objectFit: 'cover' }}
                />
                {item.title_visible && (
                  <Typography
                    position="absolute"
                    left={8}
                    top={12}
                    width={74}
                    whiteSpace="normal"
                    fontSize="10px"
                    lineHeight="1.5em"
                    fontWeight={700}
                    style={{ wordWrap: 'break-word' }}
                  >
                    {item.category_name}
                  </Typography>
                )}

                <Box
                  position="absolute"
                  width={60}
                  height={16}
                  padding="2px 8px 2px 8px"
                  gap={0}
                  borderRadius="8px 0px 8px 0px"
                  bottom={0}
                  right={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ backgroundColor: item.category_label?.hex_background_color }}
                >
                  <Typography fontSize={10} color={item.category_label?.hex_font_color}>
                    {item.category_label?.name}
                  </Typography>
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default CatalogueV2
