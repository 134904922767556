import client from 'config/apiServiceConfig'

import type { AddressFormType } from './requests'

import type {
  AddressResponseType,
  SearchLocationResponseType,
  ReverseGeocodeType,
  AddressInstructionResponseType,
  PlaceDetailResponseType,
  AddressDetailResponseType,
  AddressType,
} from './responses'

export const getAddressListService = async (
  params: Partial<{
    pageIndex?: string
    pageSize?: string
    mode?: string
  }> = {},
) =>
  client.Get<AddressResponseType, true>({
    url: `/api/customer-address`,
    params: {
      mode: 'history',
      ...params,
    },
  })

export const getReverseGeocodeService = async (params: { latLng: string }) =>
  client.Get<ReverseGeocodeType, true>({
    url: `/api/location/maps/reverse-geocode`,
    params,
  })

/* TODO: fix params interface */
export const searchMapLocationService = async (params: Record<string, unknown>) =>
  client.Get<SearchLocationResponseType, true>({
    url: `/api/location/maps/autocomplete`,
    params,
  })

export const getAddressInstructionService = async () =>
  client.Get<AddressInstructionResponseType, true>({
    url: `/api/location/instruction`,
  })

export const updateAddressService = async ({
  addressId,
  payload,
}: {
  addressId: number
  payload: AddressFormType
}) =>
  client.Put<{ message: string }>({
    url: `/api/customer-address/history/${addressId}`,
    data: payload,
  })

export const createAddressService = async (payload: AddressFormType) =>
  client.Post<AddressType, true>({
    url: `/api/customer-address`,
    data: payload,
  })

export const getPlaceDetailsService = async (params: { place_id: string; session_token: string }) =>
  client.Get<PlaceDetailResponseType, true>({
    url: '/api/location/maps/place-detail',
    params: {
      fields: 'name,formatted_address,geometry,place_id,address_components',
      region: 'ID',
      language: 'id',
      ...params,
    },
  })

export const deleteAddressService = async (addressId: number) =>
  client.Delete<{ message: string }, true>({
    url: `/api/customer-address/history/${addressId}`,
  })

/* NOTE: this service caught CORS */
export const getAddressDetailByGeoService = async (lat: number, long: number) =>
  client.Get<AddressDetailResponseType, true>({
    url: `/accounts/v2/customer-address`,
    params: {
      current_latitude: lat,
      current_longitude: long,
    },
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-APP-VERSION': undefined,
      },
    },
  })

/* NOTE: this service caught CORS */
export const postAddressPrecisionService = async (payload: {
  destination_latitude: string
  destination_longitude: string
  origin_latitude: string
  origin_longitude: string
}) =>
  client.Post<
    {
      data: {
        status: boolean
      }
      error: {
        status: boolean
        message: string
        code: number
      }
    },
    true
  >({
    url: '/api/customer-address/address-precision',
    data: payload,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-APP-VERSION': undefined,
      },
    },
  })
