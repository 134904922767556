'use client'

import { Alert } from '@astronautsid/wpe-astro-ui/components/atoms/Alert'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'

type AlertLocationClosePropsType = {
  title: string
  content: string
}

const AlertLocationClose = ({ title, content }: AlertLocationClosePropsType) => {
  const renderLabel = () => (
    // eslint-disable-next-line @typescript-eslint/naming-convention
    <Typography variant="paragraph-tiny" dangerouslySetInnerHTML={{ __html: content }} />
  )

  return <Alert content={[renderLabel]} title={title} type="error" line />
}

export default AlertLocationClose
