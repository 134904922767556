'use client'

import React from 'react'
import { Box } from '@mui/material'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import Image from 'components/Image'
import { ASSET_PREFIX } from 'config/constants'
import { CatalogueV2PropsType } from '../CatalogueV2/CatalogueV2'

const CatalogueV2Placeholder = ({
  title,
  boxProps,
  initialData,
  initialBigCatalogue,
}: CatalogueV2PropsType) => {
  const titleCatalogue = title || ''
  const shownList = initialData || []

  const bigTileData = initialBigCatalogue?.filter((cat) => !cat.is_hidden)[0]

  return (
    <Box px="1rem" {...boxProps}>
      <Typography variant="headline-default">{titleCatalogue}</Typography>
      <Box
        display="flex"
        gap="8px"
        overflow="auto"
        whiteSpace="nowrap"
        marginTop="8px"
        className="box-hide-scroll"
      >
        {/* will render only small tiles if no bigdata present */}
        {/* Large tile */}
        {bigTileData && (
          <Box flexShrink={0} width={112} height={188} position="relative" borderRadius="8px">
            <Image
              src={
                bigTileData?.category_image?.image_url_small ||
                `${ASSET_PREFIX}/img/placeholder-product.svg`
              }
              alt={bigTileData?.category_name}
              width={112}
              height={188}
              style={{ borderRadius: '8px' }}
              fallbackImageSrc={`${ASSET_PREFIX}/img/placeholder-product.svg`}
            />
          </Box>
        )}

        {/* Small tiles */}
        <Box
          display="grid"
          gridAutoFlow="column"
          gridTemplateRows="repeat(2,90px)"
          gap="0.5rem"
          flexShrink={0}
        >
          {shownList.map((item, index) => (
            <Box
              key={`home-catalogue-${index + 1}`}
              borderRadius="8px"
              position="relative"
              textAlign="center"
              width="100%"
              height="100%"
            >
              <Image
                src={
                  item.category_image?.url_small || `${ASSET_PREFIX}/img/placeholder-product.svg`
                }
                alt={item.category_name}
                width={90}
                height={90}
                style={{ borderRadius: '8px', objectFit: 'cover' }}
              />

              <Typography
                position="absolute"
                left={8}
                top={12}
                width={74}
                whiteSpace="normal"
                fontSize="10px"
                lineHeight="1.5em"
                fontWeight={700}
                style={{ wordWrap: 'break-word' }}
              >
                {item.category_name}
              </Typography>
              <Box
                position="absolute"
                width={60}
                height={16}
                padding="2px 8px 2px 8px"
                gap={0}
                borderRadius="8px 0px 8px 0px"
                bottom={0}
                right={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ backgroundColor: item.category_label?.hex_background_color }}
              >
                <Typography fontSize={10} color={item.category_label?.hex_font_color}>
                  {item.category_label?.name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default CatalogueV2Placeholder
