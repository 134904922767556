import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import Skeleton from '@mui/material/Skeleton'

type LoadingComponentPropsType = {
  boxProps?: BoxPropsType
}
const LoadingComponent = ({ boxProps }: LoadingComponentPropsType) => (
  <Box {...boxProps}>
    <Skeleton width="150px" height="15px" variant="rounded" style={{ marginLeft: 16 }} />
    <Box
      display="flex"
      gap="0.5rem"
      overflow="auto"
      whiteSpace="nowrap"
      marginTop="8px"
      className="box-hide-scroll"
      paddingLeft="16px"
    >
      <Box width={112} height={188} position="relative" borderRadius="8px">
        <Skeleton sx={{ margin: '0 auto' }} width={112} height={188} variant="rounded" />
      </Box>
      <Box
        display="grid"
        gridAutoFlow="column"
        gridTemplateRows="repeat(2,90px)"
        gap="0.5rem"
        flexShrink={0}
      >
        {[...Array(6)].map((_, i) => (
          <Skeleton
            // eslint-disable-next-line react/no-array-index-key
            key={`loading-catalog-skeleton + ${i}}`}
            sx={{ margin: '0 auto' }}
            width={90}
            height={90}
            variant="rounded"
          />
        ))}
      </Box>
    </Box>
  </Box>
)

export default LoadingComponent
