import instance, { type ConfigAxiosType } from 'config/apiServiceConfig'

import { ENDPOINTS } from 'app/order/_constants'
import type { HttpGetOrderDetailInterface } from 'app/order/_types/orderDetail'

interface ParameterInterface {
  orderID: string
}

/**
 * @function getOrderDetail
 * @param params
 */
async function getOrderDetail(params: ParameterInterface, config?: ConfigAxiosType) {
  const { orderID } = params
  return instance.Get<HttpGetOrderDetailInterface, true>({
    url: `${ENDPOINTS.GET_ORDER}/${orderID}`,
    config,
  })
}

export default getOrderDetail
