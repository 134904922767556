import axiosInstanceApiGateway from 'config/apiServiceConfig'

const { Get } = axiosInstanceApiGateway

export interface DynamicChannelInterface {
  header: DynamicChannelHeaderType
  data: DynamicChannelDataType[]
  error: ErrorType
}

export interface DynamicChannelDetailInterface {
  header: DynamicChannelHeaderType
  data: {
    title: string
    category_id: number[]
    products: {
      astroCoin: number
      availableOnAndroid: boolean
      availableOnIos: boolean
      inventoryDiscounts: ''
      isTwentyOne: boolean
      productDiscountDailyQuota: number
      productDiscountPercentage: string
      productDiscountPrice: string
      productDiscountStock: number
      productFavorite: boolean
      productGrammationLabel: string
      productId: number
      productInventoryDiscountId: number
      productName: string
      productPrice: string
      productStock: number
      productUrlImage: string
      productVolume: number
      productWeight: string
      product_slug: string
      inventory_discount_tier_label: InventoryDiscountTierLabelType[]
      product_labels: ProductLabelType[]
    }[]
  }
  error: ErrorType
}

type DynamicChannelHeaderType = {
  process_time: string
  message: string
}

export type DynamicChannelDataType = {
  dynamic_channel_id: number
  dynamic_channel_title: string
  is_active: boolean
  start_date: string
  end_date: string
  section_name: string
  image_url: string
  hubs: unknown[]
  products: DynamicChannelProductType[]
}

export type DynamicChannelProductType = {
  product_id: number
  product_name: string
  product_url_image: string
  product_price: string
  product_stock: number
  remaining_qty: number
  product_favorite: boolean
  is_twenty_one: boolean
  product_inventory_discount_id: number
  product_discount_price: string
  product_discount_percentage: string
  product_discount_stock: number
  product_discount_daily_quota: number
  product_grammation_label: string
  available_on_android: boolean
  available_on_ios: boolean
  product_volume: number
  product_weight: string
  astro_coin: number
  inventory_discount_tier_label: InventoryDiscountTierLabelType[]
  product_labels: ProductLabelType[]
  product_label: string
  variant_relative_product_ids: number[]
  brand_id: number
  is_product_variant: boolean
  category_ids: number[]
  masterVariants: DynamicChannelMasterVariantType[]
  masterVariantVariants: DynamicChannelMasterVariantVariantType[]
  location_type_icon_url: string
  location_type_label_text: string
  location_type_label_hex_code: string
  variantIds: number[]
  voucher_labels: DynamicChannelVoucherLabelType[]
  location_type: string
  is_eighteen: boolean
  product_slug?: string
}

type DynamicChannelMasterVariantType = {
  master_variant_id: number
  master_variant_name: string
  master_variant_level: number
}

type DynamicChannelMasterVariantVariantType = {
  masterVariantId: number
  variants: DynamicChannelVariantType[]
}

type DynamicChannelVariantType = {
  id: number
  name: string
  master_variant_id: number
  master_variant_name: string
  master_variant_deleted: boolean
  master_variant_active: boolean
  deleted: boolean
}

type DynamicChannelVoucherLabelType = {
  voucher_id: number
  image_url: string
  voucher_title: string
  voucher_minimum_fmt: string
  font_color: string
}

type ErrorType = {
  status: boolean
  message: string
  code: number
}

type InventoryDiscountTierLabelType = {
  qty: number
  discount_price: number
  discount_price_fmt: string
  discount_percentage: number
  discount_percentage_fmt: string
  final_price: number
  final_price_fmt: string
  label: string
}

type ProductLabelType = {
  label_id: number
  label_name: string
  label_type_id: number
  label_icon_url: string
  label_color_id: number
  label_color_name: string
  label_color_hexacode: string
  label_color_hexafont: string
  label_color_description: string
  is_primary: boolean
}

type GetDetailDynamicChannelRequestType = Partial<{
  location_id: number
  f_active: boolean
  fm_product: boolean
  show_hub: boolean
}>

type GetDynamicChannelParamsType = Partial<{
  locationid: number
  factive: boolean
  fmproduct: boolean
  showhub: boolean
}>

export const getDynamicChannel = (params: GetDynamicChannelParamsType) =>
  Get<DynamicChannelInterface, true>({
    url: `/api/dynamic-channel-location`,
    params,
  })

export const getDetailDynamicChannel = (
  id: string | number,
  params?: GetDetailDynamicChannelRequestType,
) =>
  Get<DynamicChannelDetailInterface, true>({
    url: `/api/homepage-slotting/dynamic-channel/${id}`,
    params,
  })
