'use client'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { NEW_BANNER_HEIGHT } from 'app/(home)/_config/banner'
import { GetBannerContentType } from 'utils/apiList/lite/banner'
import HomeHeader from 'app/(home)/_components/HomeHeader'

import useNewHomeHeader from 'app/(home)/_components/NewHomeHeader/useNewHomeHeader'
import NewBannerSliderSection from './NewBannerSection/NewBannerSliderSection'

type NewHomeHeaderPropsType = {
  bannerData?: GetBannerContentType[]
  isGuestMode?: boolean
}

const NewHomeHeader = ({ bannerData = [], isGuestMode = false }: NewHomeHeaderPropsType) => {
  const { textColor, tickerShown, handleChangeTextColor, handleTickerShown } = useNewHomeHeader({
    initialTextColor: bannerData?.[0]?.text_color || undefined,
  })

  let additionalHeight = 0

  if (tickerShown) {
    additionalHeight = 55
  }

  const currentHeight = NEW_BANNER_HEIGHT + additionalHeight

  return (
    <Box
      sx={{
        position: 'relative',
        height: `${currentHeight}px`,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          zIndex: 11,
          position: 'relative',
        }}
      >
        <HomeHeader
          handleTickerShown={handleTickerShown}
          textColor={textColor}
          isGuestMode={isGuestMode}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 10,
        }}
      >
        <NewBannerSliderSection
          handleChangeTextColor={handleChangeTextColor}
          tickerShown={tickerShown}
          banners={bannerData}
        />
      </Box>
    </Box>
  )
}

export default NewHomeHeader
