'use client'

import {
  TrackEventsPropertiesType,
  getScreenNameMetaFromDocument,
  trackEvents,
} from 'lib/mixpanel/mixpanelTracker'
import { useEffect } from 'react'

type TrackingWrapperType = {
  event: string
  properties: TrackEventsPropertiesType
  children: React.ReactNode
}

const TrackingWrapper = ({ event, properties, children }: TrackingWrapperType) => {
  useEffect(() => {
    const screenName = getScreenNameMetaFromDocument()

    trackEvents(event, {
      screenName,
      ...properties,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export default TrackingWrapper
