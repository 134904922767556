'use client'

import { type ReactNode } from 'react'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Accordion } from '@astronautsid/wpe-astro-ui/components/atoms/Accordion'
import { AccordionSummary } from '@astronautsid/wpe-astro-ui/components/atoms/AccordionSummary'
import { AccordionDetails } from '@astronautsid/wpe-astro-ui/components/atoms//AccordionDetails'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

type HomeBottomContentPropsType = {
  content: { title: ReactNode; content: ReactNode }[]
}

const HomeBottomContent = ({ content }: HomeBottomContentPropsType) => {
  const theme = useTheme()

  return (
    <Box py="16px">
      {content.map((i, idx) => {
        const key = idx + 1

        return (
          <Accordion
            key={key}
            sx={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '::after': {
                bgcolor: theme.palette.strokeColor.subtle,
              },
            }}
          >
            <AccordionSummary>{i.title}</AccordionSummary>
            <AccordionDetails>{i.content}</AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )
}

export default HomeBottomContent
