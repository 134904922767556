'use client'

import Link from 'next/link'

import { Alert } from '@astronautsid/wpe-astro-ui/components/atoms/Alert'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { URL_APP_AREA } from 'config/constants'

const AlertUnreachAddress = () => {
  const renderLabel = () => (
    <Typography variant="paragraph-tiny">
      {'Lokasimu belum terjangkau. Kasih saran lokasi selanjutnya buat Astro, yuk! '}
      <Link href={URL_APP_AREA} target="_blank">
        <Typography variant="body-tinyStrong" color="textColor.link">
          Kasih Saran
        </Typography>
      </Link>
    </Typography>
  )

  return <Alert line content={[renderLabel]} type="error" />
}

export default AlertUnreachAddress
