'use client'

import React from 'react'
import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { color } from '@astronautsid/wpe-astro-ui/tokens/color'
import Link from 'next/link'

import ProductCard from 'components/ProductCard'
import { mixpanelEventCategory } from 'utils/tracking/mixpanel/mixpanelConstants'

import { type DynamicChannelInterface } from '../../_utils/services/dynamicChannel'

type CatalogueSectionPropsType = {
  boxProps?: BoxPropsType
  initialData?: DynamicChannelInterface['data']
}

const DynamicChannel = ({ boxProps, initialData = [] }: CatalogueSectionPropsType) => {
  const dynamicChannelList = initialData

  if (dynamicChannelList.length === 0) {
    return null
  }

  return (
    <Box {...boxProps}>
      {dynamicChannelList.map((content, index) => (
        <Box key={`dynamic-channel-${index + 1}`} mt={index > 0 ? '16px' : undefined}>
          <Box
            marginBottom="8px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="8px"
            px="16px"
          >
            <Typography component="h2" variant="headline-default">
              {content.dynamic_channel_title}
            </Typography>
            <Link href={`/d/${content.dynamic_channel_id}`}>
              <Typography
                variant="body-smallStrong"
                color={color.textColor.link}
                whiteSpace="nowrap"
              >
                Lihat Semua
              </Typography>
            </Link>
          </Box>
          <Box display="flex" overflow="auto" gap="14px" className="box-hide-scroll" px="16px">
            {content.products.map((product, indexProduct) => (
              <Box key={`dynamic-channel-product-${indexProduct + 1}`}>
                <ProductCard
                  boxPropsContainer={{
                    width: '110px',
                  }}
                  productData={{
                    astroCoin: product.astro_coin,
                    productDiscountDailyQuota: product.product_discount_daily_quota,
                    productDiscountPercentage: product.product_discount_percentage,
                    productDiscountPrice: product.product_discount_price,
                    productDiscountStock: product.product_discount_stock,
                    productFavorite: product.product_favorite,
                    productGrammationLabel: product.product_grammation_label,
                    productID: product.product_id,
                    productInventoryDiscountId: product.product_inventory_discount_id,
                    productName: product.product_name,
                    productPrice: product.product_price,
                    productStock: product.product_stock,
                    productUrlImage: product.product_url_image,
                    productVolume: product.product_volume,
                    productWeight: product.product_weight,
                    productLabels: product.product_labels,
                    productCategoryIds: product.category_ids || [],
                    isEighteen: product.is_eighteen,
                    productSlug: product.product_slug,
                    inventoryDiscountTierLabel: product?.inventory_discount_tier_label,
                  }}
                  source="dynamicchannel"
                  trackerData={{
                    eventData: {
                      eventLabel: content.dynamic_channel_title || '',
                      item_list: `/${mixpanelEventCategory.RECOMMENDATION_PAGE} - product list -  recommendation ${content.dynamic_channel_title}`,
                      items: [
                        {
                          item_name: product.product_name,
                          item_id: String(product.product_id),
                          price: Number(product.product_price),
                          item_brand: '',
                          item_category: '',
                          item_variant: '',
                          quantity: product.product_stock,
                          item_category5:
                            product.product_discount_price || product.product_price || '',
                          item_category4: `${product.product_inventory_discount_id || ''}`,
                          promotion_id: '',
                          item_list_id: `${indexProduct}`,
                          item_category3: `${product.product_discount_daily_quota || ''}`,
                          item_list_name: '',
                          promotion_name: '',
                        },
                      ],
                    },
                    eventAction: {
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      viewProduct: `view product card - recommendation`,
                      clickProduct: `click product card - recommendation`,
                      addToCart: `click add to cart on product card - recommendation`,
                    },
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default DynamicChannel
