import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import { ASSET_PREFIX } from 'config/constants'
import Image from 'components/Image'

import {
  NEW_BANNER_ASPECT_RATIO,
  NEW_BANNER_HEIGHT,
  NEW_MAX_WIDTH_BANNER_CONTAINER,
} from 'app/(home)/_config/banner'

type BannerPropsType = {
  onClick?: () => void
  onLoad?: () => void
  onError?: () => void
  priority?: boolean
  src: string
  alt: string
  boxProps?: BoxPropsType
  href?: string
  tickerShown?: boolean
}

const NewBanner = ({
  onClick,
  onLoad,
  onError,
  priority,
  src,
  alt,
  boxProps,
  href,
  tickerShown = false,
}: BannerPropsType) => {
  const renderImage = () => (
    <Image
      src={src || `${ASSET_PREFIX}/img/placeholder-product.svg`}
      loading="eager"
      alt={alt}
      priority={priority}
      containerProps={{
        sx: {
          aspectRatio: NEW_BANNER_ASPECT_RATIO,
          position: 'relative',
          maxHeight: !tickerShown ? NEW_BANNER_HEIGHT : `calc(${NEW_BANNER_HEIGHT} + 55px)`,
          width: '100%',
        },
      }}
      style={{
        objectFit: 'cover',
        objectPosition: 'bottom',
        width: '100%',
        height: '100%',
      }}
      fill
      onLoad={onLoad}
      onError={onError}
    />
  )

  return (
    <Box
      sx={{
        aspectRatio: NEW_BANNER_ASPECT_RATIO,
        margin: '0 auto',
        position: 'relative',
      }}
      onClick={onClick}
      {...boxProps}
      {...NEW_MAX_WIDTH_BANNER_CONTAINER}
    >
      {href ? <a href={href}>{renderImage()}</a> : renderImage()}
    </Box>
  )
}

export default NewBanner
