'use client'

import useQueryBanner from 'app/(home)/_hooks/useQueryBanner'

import useUser from 'hooks/useUser'
import NewHomeHeader from './NewHomeHeader'
import NewBannerSkeleton from './NewBannerSection/BannerSkeleton'

const NewHomeHeaderContainer = () => {
  const { user } = useUser()
  const { data: bannerData, isLoading } = useQueryBanner()
  const newBannerData = bannerData?.content?.filter((i) => i.banner_url_image) || []

  if (isLoading) {
    return <NewBannerSkeleton />
  }

  return <NewHomeHeader isGuestMode={!user} bannerData={newBannerData} />
}

export default NewHomeHeaderContainer
