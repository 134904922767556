'use client'

import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'

import { getScreenNameMetaFromDocument, trackEvents } from 'lib/mixpanel/mixpanelTracker'
import { mixpanelEventCategory, mixpanelEventName } from 'utils/tracking/mixpanel/mixpanelConstants'
import TrackingWrapper from 'components/MixPanel/TrackingWrapper'

import { trackAppMonitoring } from 'utils/tracking/mixpanel/mixpanelEventActions'
import { CategoryContentType } from 'utils/apiList/lite/liteApiTypes'

import { CategorySectionPropsType } from './types'
import CategoryCard from '../CategoryCard'
import ExpandedCard from './ExpandedCard'

const CategorySection = ({ title, initialData = [], boxProps }: CategorySectionPropsType) => {
  const [expanded, setExpanded] = useState(false)

  const handleTrackCategoryLoadSuccess = () => {
    trackAppMonitoring({
      eventAction: 'app monitoring - category load',
      eventLabel: 'success',
    })
  }

  useEffect(() => {
    if (initialData.length) {
      const screenName = getScreenNameMetaFromDocument()

      trackEvents(mixpanelEventName.VIEW_COMPONENT, {
        eventCategory: screenName,
        eventAction: 'view internal asset - category',
        eventLabel: '',
      })
    }
  }, [initialData.length])

  useEffect(() => {
    if (!initialData.length) {
      return
    }

    handleTrackCategoryLoadSuccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTrackEventClick = (item: CategoryContentType, index: number) => {
    const screenName = getScreenNameMetaFromDocument()

    trackEvents(mixpanelEventName.SELECT_PROMOTION, {
      screenName,
      eventAction: `click category - ${item.category_name}`,
      eventCategory: mixpanelEventCategory.HOME_PAGE,
      eventLabel: '',
      items: [
        {
          item_id: item.category_id,
          item_name: `/homepage - banner category`,
          creative_name: item.category_name,
          creative_slot: index,
        },
      ],
    })
  }

  const titleCategory = title || ''
  const shownList = initialData

  const mainShownList = shownList.slice(0, 15)
  const restShownList = shownList.slice(16, shownList.length)

  return (
    <TrackingWrapper
      event={mixpanelEventName.VIEW_PROMOTION}
      properties={{
        eventAction: `view category - ${titleCategory}`,
        eventCategory: mixpanelEventCategory.HOME_PAGE,
        eventLabel: '',
        items: shownList.map((item, index) => ({
          item_id: item.category_id,
          item_name: `/homepage - banner category`,
          creative_name: item.category_name,
          creative_slot: index,
        })),
      }}
    >
      <Box px="16px" {...boxProps}>
        <Typography component="h2" variant="headline-default">
          {titleCategory}
        </Typography>
        <Grid container spacing={3} pt="8px">
          {mainShownList.map((content, index) => (
            <Grid key={`home-category-${content.category_id}`} item xs={3}>
              <CategoryCard
                title={content?.category_name || ''}
                image={content?.category_image?.url_small || ``}
                boxPropsContainer={{ width: '100%', height: '100%' }}
                onClick={() => handleTrackEventClick(content, index)}
                slug={content?.category_slug || ''}
                titleVisible={content.title_visible}
              />
            </Grid>
          ))}
          {!expanded && !!restShownList.length && (
            <Grid item xs={3}>
              <ExpandedCard
                title={`Cek Kategori Lainnya (${restShownList.length})`}
                onClick={() => setExpanded(true)}
              />
            </Grid>
          )}
          {restShownList.map((content, index) => (
            <Grid
              key={`home-category-${content.category_id}`}
              item
              xs={3}
              display={expanded ? 'initial' : 'none'}
            >
              <CategoryCard
                title={content?.category_name || ''}
                image={content?.category_image?.url_small || ``}
                boxPropsContainer={{
                  width: '100%',
                  height: '100%',
                }}
                onClick={() => handleTrackEventClick(content, index)}
                slug={content?.category_slug || ''}
                titleVisible={content.title_visible}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </TrackingWrapper>
  )
}

export default CategorySection
