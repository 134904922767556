import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import Skeleton from '@mui/material/Skeleton'

import {
  NEW_BANNER_ASPECT_RATIO,
  NEW_BANNER_HEIGHT,
  NEW_MAX_WIDTH_BANNER_CONTAINER,
} from 'app/(home)/_config/banner'

const NewBannerSkeleton = () => (
  <Box
    position="relative"
    sx={{ aspectRatio: NEW_BANNER_ASPECT_RATIO, maxHeight: NEW_BANNER_HEIGHT }}
    margin="0 auto"
    {...NEW_MAX_WIDTH_BANNER_CONTAINER}
  >
    <Skeleton width="100%" height="100%" variant="rounded" />
  </Box>
)

export default NewBannerSkeleton
