import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { logger, sliceResetFns } from 'utils/store'

export type LocationStateType = {
  latitude?: Nullable<number>
  longitude?: Nullable<number>
  error?: Nullable<string>
  isPermissionDenied: boolean
  askedForPermission: boolean
}

export type LocationActionType = {
  updateLocation: (location: Partial<LocationStateType>) => void
  setPermissionDenied: (status: boolean) => void
  setPermissionAsked: (status: boolean) => void
}

export const locationInitialState: LocationStateType = {
  // initial lat long Tugu Monas
  latitude: -6.175072396340333,
  longitude: 106.82708842262313,
  error: null,
  isPermissionDenied: false,
  askedForPermission: false,
}

export type LocationSliceType = LocationStateType & LocationActionType

export const SLICE_NAME = 'locationStore'

const useLocationStore = create<LocationSliceType>()(
  logger(
    persist(
      immer((set) => {
        sliceResetFns.add(() => set(() => locationInitialState))
        return {
          ...locationInitialState,
          updateLocation: (location) =>
            set((state) => {
              state.latitude = location.latitude
              state.longitude = location.longitude
              state.error = location.error
            }),
          setPermissionDenied: (status: boolean) =>
            set((state) => {
              state.isPermissionDenied = status
            }),
          setPermissionAsked: (status: boolean) =>
            set((state) => {
              state.askedForPermission = status
            }),
        }
      }),
      {
        name: SLICE_NAME,
        storage: createJSONStorage(() => localStorage),
      },
    ),
    SLICE_NAME,
  ),
)

export default useLocationStore
