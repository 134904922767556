import Dialog from '@mui/material/Dialog'
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Button } from '@astronautsid/wpe-astro-ui/components/atoms/Button'

export type ConfirmationDialogPropsType = {
  open: boolean
  handleClose?: () => void
  handleOk?: () => void
  title: string
  description: string
  okText?: string
  cancelText?: string
  withCancelBtn?: boolean
  withOkBtn?: boolean
  dialogContentProps?: DialogContentProps
}

const ConfirmationDialog = ({
  open,
  handleClose = () => {},
  handleOk = () => {},
  title,
  description,
  okText = 'Ya',
  cancelText = 'Batal',
  dialogContentProps,
  withCancelBtn = true,
  withOkBtn = true,
}: ConfirmationDialogPropsType) => (
  <Dialog
    open={open}
    onClose={(_, reason) => {
      if (reason === 'backdropClick') {
        return
      }
      handleClose()
    }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    PaperProps={{
      sx: {
        margin: '0 16px',
        borderRadius: '8px',
      },
    }}
  >
    <DialogContent
      {...dialogContentProps}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        textAlign: 'center',
        maxWidth: '312px',
        p: '16px',
        ...dialogContentProps?.sx,
      }}
    >
      <Typography variant="headline-large">{title}</Typography>
      <Typography variant="paragraph-small" color="textColor.secondaryDark">
        {description}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
        }}
      >
        {withCancelBtn && (
          <Button size="small" fullWidth variant="outlined" color="neutral" onClick={handleClose}>
            {cancelText}
          </Button>
        )}
        {withOkBtn && (
          <Button size="small" fullWidth variant="contained" onClick={handleOk}>
            {okText}
          </Button>
        )}
      </Box>
    </DialogContent>
  </Dialog>
)

export default ConfirmationDialog
