'use client'

import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import AlertUnreachAddress from './AlertUnreachAddress'
import AlertLocationClose from './AlertLocationClose/AlertLocationClose'
import useAlertErrorAddress from './useAlertErrorAddress'

type AlertErrorAddressPropsType = {
  boxPropsContainer?: BoxPropsType
  handleTickerShown?: (status: boolean) => void
}

const AlertErrorAddress = ({
  boxPropsContainer,
  handleTickerShown,
}: AlertErrorAddressPropsType) => {
  const handleShowTicker = handleTickerShown || (() => {})

  const { hasLocationCloseReason, unreachableAddress, closeReasonTitle, closeReasonContent } =
    useAlertErrorAddress()

  const renderContent = () => {
    if (unreachableAddress) {
      handleShowTicker(true)
      return <AlertUnreachAddress />
    }

    if (hasLocationCloseReason) {
      handleShowTicker(true)
      return <AlertLocationClose title={closeReasonTitle} content={closeReasonContent} />
    }

    return null
  }

  return (
    <Box
      sx={{ mt: unreachableAddress || hasLocationCloseReason ? '8px' : '0px' }}
      {...boxPropsContainer}
    >
      {renderContent()}
    </Box>
  )
}

export default AlertErrorAddress
