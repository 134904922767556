import { BoxPropsType } from '@astronautsid/wpe-astro-ui'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

type CategorySectionSkeletonPropsType = {
  boxPropsContainer?: BoxPropsType
}

const CategorySectionSkeleton = ({ boxPropsContainer }: CategorySectionSkeletonPropsType) => (
  <Box px="16px" {...boxPropsContainer}>
    <Skeleton width="150px" height="16px" variant="rounded" />
    <Grid container spacing={3} pt="8px">
      {[...new Array(8)].map((_, index) => (
        <Grid key={`home-category-loading-${index + 1}`} item xs={3}>
          <Skeleton
            sx={{ margin: '0 auto', aspectRatio: 1 }}
            width="100%"
            height="auto"
            variant="rounded"
          />
        </Grid>
      ))}
    </Grid>
  </Box>
)

export default CategorySectionSkeleton
