import useUserStore from 'store/user'
import { useQuery } from 'lib/reactQuery'
import { getAddressListService } from 'app/account/address/_utils/services'

const useAlertErrorAddress = () => {
  const { token, customerAddress } = useUserStore((state) => state)

  const { data } = useQuery({
    queryKey: ['address-alert', token, customerAddress.location_id],
    queryFn: async () => getAddressListService({ mode: undefined }),
    select: (resp) => resp.data.content?.[0] || null,
    enabled: !!token,
    gcTime: 0,
    staleTime: 0,
  })

  const closeReasonTitle = data?.location_close_reason?.reason_title || ''
  const closeReasonContent = data?.location_close_reason?.reason_content || ''

  const hasLocationCloseReason = !!closeReasonTitle && !!closeReasonContent

  const unreachableAddress = data && !data.location_id

  return {
    hasLocationCloseReason,
    unreachableAddress,
    closeReasonTitle,
    closeReasonContent,
  }
}

export default useAlertErrorAddress
