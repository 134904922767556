import Grid from '@mui/material/Grid'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { CategorySectionPropsType } from 'app/(home)/_components/CategorySection/types'
import CategoryCard from 'app/(home)/_components/CategoryCard'
import ExpandedCard from '../CategorySection/ExpandedCard'

const CategorySectionPlaceholder = ({ title, boxProps, initialData }: CategorySectionPropsType) => {
  const expanded = false

  const titleCategory = title
  const shownList = initialData || []

  const mainShownList = shownList.slice(0, 15)
  const restShownList = shownList.slice(16, shownList.length)

  return (
    <Box px="16px" {...boxProps}>
      <Typography variant="headline-default">{titleCategory}</Typography>
      <Grid container spacing={3} pt="8px">
        {mainShownList.map((content, index) => (
          <Grid key={`home-category-${index + 1}`} item xs={3}>
            <CategoryCard
              title={content.category_name}
              image={content.category_image?.url_small || ``}
              boxPropsContainer={{ width: '100%', height: '100%' }}
              slug={content?.category_slug || ''}
              titleVisible={content.title_visible}
            />
          </Grid>
        ))}
        {!expanded && !!restShownList.length && (
          <Grid item xs={3}>
            <ExpandedCard title={`Cek Kategori Lainnya (${restShownList.length})`} />
          </Grid>
        )}
        {restShownList.map((content, index) => (
          <Grid
            key={`home-category-${index + 1}`}
            item
            xs={3}
            display={expanded ? 'initial' : 'none'}
          >
            <CategoryCard
              title={content.category_name}
              image={content.category_image?.url_small || ``}
              boxPropsContainer={{
                width: '100%',
                height: '100%',
              }}
              slug={content?.category_slug || ''}
              titleVisible={content.title_visible}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default CategorySectionPlaceholder
