'use client'

import { type ReactNode, useState } from 'react'

import noop from 'utils/function/noop'
import useIntersection from 'hooks/useIntersection'

const LoadableComponent = ({ children }: { children: ReactNode }) => {
  const [showComponent, setShowComponent] = useState(false)

  const handleInView = () => {
    setShowComponent(true)
  }

  const ref = useIntersection<HTMLSpanElement>({
    callback: showComponent ? noop : handleInView,
    onlyOnce: false,
  })

  return (
    <>
      {!showComponent && <span ref={ref} />}
      {showComponent && children}
    </>
  )
}

export default LoadableComponent
