'use client'

import useLocationStore from 'store/location/slice'
import useUserStore from 'store/user'

export type GeolocationDataType = {
  latitude: number | null
  longitude: number | null
  error: string | null
}

const useLocation = () => {
  const {
    updateLocation,
    latitude,
    longitude,
    setPermissionDenied,
    isPermissionDenied,
    setPermissionAsked,
    askedForPermission,
  } = useLocationStore()

  const { isLoggedIn } = useUserStore((state) => state)

  const getLocation = () => {
    if (!isLoggedIn) {
      return
    }

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude
          const long = position.coords.longitude

          updateLocation({
            latitude: lat,
            longitude: long,
            error: null,
          })
          setPermissionDenied(false)
        },
        (error) => {
          setPermissionDenied(true)
          updateLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          })
        },
      )
    } else {
      updateLocation({
        latitude: null,
        longitude: null,
        error: 'Geolocation is not available in this browser.',
      })
    }
  }

  return {
    latitude,
    longitude,
    getLocation,
    isPermissionDenied,
    updateLocation,
    setPermissionAsked,
    askedForPermission,
  }
}

export default useLocation
