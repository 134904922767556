import { useEffect, useMemo, useRef } from 'react'
import useRouter from 'hooks/useRouter'
import { useQuery } from '@tanstack/react-query'

import useUserStore from 'store/user'
import useUser from 'hooks/useUser'

import { customerUserAddressesService } from 'app/account/_utils/services'
import { getScreenNameMetaFromDocument, trackEvents } from 'lib/mixpanel/mixpanelTracker'
import { mixpanelEventName } from 'utils/tracking/mixpanel/mixpanelConstants'
import { LINK_CHAT_CS } from 'config/constants'

const DEFAULT_TEXT_ADDRESS_LABEL = 'Belanja hitungan menit!'

function useHomeHeader(isGuestMode: boolean) {
  const router = useRouter()
  const { login } = useUser()

  const checkFirstTime = useRef(true)

  const { token, isLoggedIn, user, resetUserState } = useUserStore((state) => state)

  const {
    data: userAddresses,
    isLoading: isLoadingAddress,
    isFetched: isFetchedAddress,
  } = useQuery({
    queryKey: ['userAddresses', token],
    queryFn: () =>
      customerUserAddressesService(String(token))
        .then((res) => {
          const screenName = getScreenNameMetaFromDocument()

          trackEvents(mixpanelEventName.APP_MONITORING, {
            screenName,
            eventCategory: screenName,
            eventAction: 'app monitoring - address load',
            eventLabel: 'success',
          })

          return res
        })
        .catch((err) => {
          const screenName = getScreenNameMetaFromDocument()

          trackEvents(mixpanelEventName.APP_MONITORING, {
            screenName,
            eventCategory: screenName,
            eventAction: 'app monitoring - address load',
            eventLabel: `failed - ${err?.message as string}}`,
          })
        }),
    enabled: !!token,
    gcTime: 0,
    staleTime: 0,
  })

  const addressLabel = useMemo(() => {
    if (!isLoggedIn) {
      return {
        label: DEFAULT_TEXT_ADDRESS_LABEL,
        detail: '',
      }
    }

    if (userAddresses?.data?.content?.[0]) {
      const data =
        userAddresses.data.content.find((el) => el.default_address) ||
        userAddresses.data.content[0] ||
        ''

      return {
        label: data?.label_address || '',
        detail: data?.address || '',
      }
    }
    return {
      label: 'Masukkan alamat',
      detail: '',
    }
  }, [userAddresses, isLoggedIn])

  const handleChangeAddress = () => {
    router.push('/account/address/search?source=home&root=true')
  }

  const handleClickChatCS = () => {
    if (!isLoggedIn) {
      login()

      return
    }
    window.location.assign(LINK_CHAT_CS)
  }

  useEffect(() => {
    if (isGuestMode && checkFirstTime.current) {
      checkFirstTime.current = false
      resetUserState()
    }
  }, [isGuestMode, resetUserState])

  return {
    addressLabel,
    isLoadingAddress: (isLoadingAddress || !isFetchedAddress) && !!token,
    name: user?.first_name || '',
    handleChangeAddress,
    isLoggedIn,
    handleClickChatCS,
  }
}

export default useHomeHeader
