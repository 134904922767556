'use client'

import dynamic from 'next/dynamic'

import { CategorySectionPlaceholder } from 'app/(home)/_components/Placeholders'
import DynamicChannel from 'app/(home)/_components/DynamicChannel/Container'
import NewHomeHeader from 'app/(home)/_components/NewHomeHeader/Container'
import LoadingComponent from '../CatalogueV2/LoadingComponent'

const CatalogueSection = dynamic(() => import('app/(home)/_components/CatalogueV2/Container'), {
  loading: () => <LoadingComponent />,
})

const CategorySection = dynamic(() => import('app/(home)/_components/CategorySection/Container'), {
  loading: () => <CategorySectionPlaceholder />,
})

const DefaultHomepageSlotting = () => {
  const wrapperProps = {
    mt: '16px',
  }

  return (
    <>
      <NewHomeHeader />
      <CatalogueSection boxProps={{ ...wrapperProps }} />
      <CategorySection boxProps={{ ...wrapperProps }} />
      <DynamicChannel boxProps={{ ...wrapperProps }} />
    </>
  )
}

export default DefaultHomepageSlotting
