'use client'

import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Skeleton } from '@mui/material'

const DynamicChannelSkeleton = (props: BoxPropsType) => (
  <Box margin="16px" {...props}>
    <Box marginTop="16px">
      <Box marginBottom="8px" display="flex" justifyContent="space-between" gap="32px">
        <Box width="80%">
          <Skeleton variant="text" width="100%" height="26px" />
          <Skeleton variant="text" width="40%" height="26px" />
        </Box>

        <Skeleton variant="text" width="20%" height="26px" />
      </Box>
      <Box display="flex" overflow="auto" gap="14px" className="box-hide-scroll">
        <Skeleton sx={{ margin: '0 auto' }} width="110px" height="164px" variant="rounded" />
        <Skeleton sx={{ margin: '0 auto' }} width="110px" height="164px" variant="rounded" />
        <Skeleton sx={{ margin: '0 auto' }} width="110px" height="164px" variant="rounded" />
      </Box>
    </Box>
  </Box>
)

export default DynamicChannelSkeleton
