import { useQuery } from 'lib/reactQuery'
import { getBanner, type GetBannerDataType } from 'utils/apiList/lite/banner'
import useUserStore from 'store/user'

type UseQueryBannerParamsType = {
  initialData?: GetBannerDataType
}

const useQueryBanner = ({ initialData }: UseQueryBannerParamsType = {}) => {
  const { customerAddress } = useUserStore((state) => state)

  return useQuery({
    queryKey: ['home-banner'],
    queryFn: async () => {
      const resp = await getBanner(`locationID=${customerAddress.location_id}`)

      return resp.data
    },
    initialData,
  })
}

export default useQueryBanner
