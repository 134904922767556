'use client'

import { Fragment } from 'react'
import dynamic from 'next/dynamic'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import LoadableComponent from 'components/LoadableComponent'

import CategorySkeleton from 'app/(home)/_components/Placeholders/CategorySkeleton'
import LoadingComponent from 'app/(home)/_components/CatalogueV2/LoadingComponent'
import NewBannerSkeleton from 'app/(home)/_components/NewHomeHeader/NewBannerSection/BannerSkeleton'

import DynamicChannelSkeleton from '../DynamicChannel/components/DynamicChannelSkeleton'
import { type HomepageSlottingType } from '../../_utils/services/homepageSlotting'

const CategorySection = dynamic(() => import('../CategorySection/Container'), {
  loading: () => <CategorySkeleton />,
})
const CatalogueSection = dynamic(() => import('../CatalogueV2/Container'), {
  loading: () => <LoadingComponent />,
})
const NewHomeHeader = dynamic(() => import('../NewHomeHeader/Container'), {
  loading: () => <NewBannerSkeleton />,
})
const DynamicChannelSlotting = dynamic(() => import('../DynamicChannel/DynamicChannelSlotting'), {
  loading: () => (
    <Box mt="16px">
      <DynamicChannelSkeleton />
    </Box>
  ),
})

type SlottingContainerPropsType = {
  initialSlottingData: HomepageSlottingType[]
}

const SlottingContainer = ({ initialSlottingData }: SlottingContainerPropsType) => {
  const slottingData = initialSlottingData

  const renderSlottingContent = (content: HomepageSlottingType) => {
    switch (content.sectionType) {
      case 'banner': {
        return (
          <Box>
            <NewHomeHeader />
          </Box>
        )
      }
      case 'catalog': {
        return (
          <Box mt="16px">
            <CatalogueSection />
          </Box>
        )
      }
      case 'category': {
        return (
          <Box mt="16px">
            <CategorySection />
          </Box>
        )
      }
      case 'dynamic-channel': {
        return (
          <LoadableComponent>
            <DynamicChannelSlotting id={content.dynamicChannelId} />
          </LoadableComponent>
        )
      }
      default:
        return null
    }
  }

  return (
    <>
      {slottingData.map((i) => {
        const key = i.id

        return <Fragment key={key}>{renderSlottingContent(i)}</Fragment>
      })}
    </>
  )
}

export default SlottingContainer
