'use client'

import React from 'react'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Back } from '@astronautsid/wpe-icons'
import { color } from '@astronautsid/wpe-astro-ui/tokens/color'

type ExpandedCardPropsType = {
  title: string
  onClick?: () => void
}

const ExpandedCard = ({ onClick, title }: ExpandedCardPropsType) => (
  <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
    <Box position="relative" borderRadius="8px" margin="0 auto">
      <Typography
        variant="caption-tinyBold"
        position="absolute"
        zIndex={1}
        textAlign="center"
        marginLeft="auto"
        marginRight="auto"
        left="0"
        right="0"
        top="12px"
        px="8px"
      >
        {title}
      </Typography>
      <Box position="absolute" left="0" right="0" bottom="12px" width="100%">
        <Box
          border="1px solid"
          borderColor={color.strokeColor.active}
          width="32px"
          height="32px"
          borderRadius="50%"
          bgcolor={color.bgColor.light}
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          margin="0 auto"
          sx={{
            rotate: '-90deg',
          }}
        >
          <Back size={16} type="outline" color={color.strokeColor.active} />
        </Box>
      </Box>
      <Box sx={{ aspectRatio: 76 / 97 }} bgcolor={color.bgColor.lightBlue} borderRadius="8px" />
    </Box>
  </Box>
)

export default ExpandedCard
